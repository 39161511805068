import { combineReducers } from "redux";
import { vendorList } from "./reducers/vendorList";
import { collectionList } from "./reducers/collectionList";
import { categorieList } from "./reducers/categorieList";
import { catalogueList } from "./reducers/catalogueList";

const rootReducer = combineReducers({
  vendorList,
  collectionList,
  categorieList,
  catalogueList,
});

export default rootReducer;
