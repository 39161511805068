import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import rootReducer from '../src/redux/rootReducer';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom"; // <-- New code

import thunk from "redux-thunk";
import ScrollToTop from './Utils/ScrollToTop';
import PreLoader from './Components/Sections/CommonSections/PreLoader';


let store = createStore(rootReducer, applyMiddleware(thunk))

const AppComponent = React.lazy(() => import('./App'));
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <Suspense fallback={<PreLoader />}>
            <AppComponent />
          </Suspense>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
